<template>
    <div>
        <el-button @click="modalVisible = true" type="default" plain class="p-1 ml-3">
            <i class="fa-solid fa-arrows-rotate cursor-pointer" title="Uppdatera från Syna" />
        </el-button>
        <el-dialog :visible.sync="modalVisible" width="25%" title="Uppdatera">
            <div class="flex flex-col space-y-2">
                <h1 class="font-bold">Är du säker att du vill uppdatera {{ cardTitle }}?</h1>
                <div class="flex flex-row justify-end">
                    <el-button @click="triggerRefreshData" type="success">Uppdatera</el-button>
                    <el-button @click="modalVisible = false" type="warning">Avbryt</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        cardTitle: {
            type: String,
            default: "",
        },
        annualReportId: {
            type: String,
            default: "",
        },
        refreshData: {
            type: Function,
            required: true,
        },
        loadingKey: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            modalVisible: false,
        };
    },
    methods: {
        async triggerRefreshData() {
            this.modalVisible = false;
            try {
                this.$waiting.start(this.loadingKey);
                await this.refreshData();
                this.$notify.success({ title: `Hämtat "${this.cardTitle}" från Syna` });
            } catch (error) {
                this.$notify.error({ title: "Fel vid uppdatering från Syna", message: error.message });
            } finally {
                this.$waiting.end(this.loadingKey);
            }
        },
    },
};
</script>
